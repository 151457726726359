$(document).foundation();




// AGENTS PROFILE GRID
var $staffprofile = $("[data-isotope='isotope-profiles']").imagesLoaded( function() {
  $staffprofile.isotope({
  itemSelector: '.agent-profile-item',
	resizable: false, // disable normal resizing
    layoutMode: 'fitRows'
  });

	$('.filter-select').on('change', function(){
		var filterValue = this.value;
		$staffprofile.isotope({ filter: filterValue});
	});

});



// IMAGE COMPARE SLIDER
var $juxtapose = $('.juxtapose'),
    $juxtapose_container = $('.juxtapose-container'),
    juxtapose_ratio;

$(window).load(function(){
  juxtapose_ratio = $juxtapose.outerHeight() / $juxtapose.outerWidth();
});

$(window).resize(function() {
  var new_width = $juxtapose_container.outerWidth(),
      new_height = new_width*juxtapose_ratio;

  $juxtapose.css({
    width: new_width,
    height: new_height
  })
});

// OBJECT FIT IMAGES
objectFitImages();


$(function() {
	$('.equal-height').matchHeight({
      byRow: true
  });
});
