// jQuery(function($) {
//   // Asynchronously Load the map API
//   var script = document.createElement('script');
//   script.src = "//maps.googleapis.com/maps/api/js?sensor=false&callback=initialize";
//   document.body.appendChild(script);
// });
//
// function initialize() {
//   var map;
//   var bounds = new google.maps.LatLngBounds();
//   var mapOptions = {
//       mapTypeId: 'roadmap',
//       scrollwheel: false,
//   };
//
//   // Display a map on the page
//   map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);
//   // map.setTilt(45);
//
//   // Multiple Markers
//   var markers = [
//     ['Glasgow', 55.864237,-4.251806],
//     ['London', 51.507351,-0.127758],
//     ['Paris', 48.856614,2.352222],
//     ['Madrid', 40.416775,-3.703790],
//     ['Sydney', -33.868820,151.209296],
//     ['New York', 40.712784,-74.005941]
//   ];
//
//   // Info Window Content
//   var infoWindowContent = [
//       ['<div class="info_content">' +
//       '<h3>Supagard UK</h3>' +
//       '<p>19-27 Gavinton Street</p>' +
//       '<p>Muirend, Glasgow</p>' +
//       '<p>United Kingdom</p>' +
//       '<p>G44 3EF</p>' +
//       '<p>Tel: +44 (0)141 663 5933</p>' +
//       '<p>hello@supagard.com</p>' +
//       '<p>www.supagard.com</p>' +
//       '</div>'],
//
//       ['<div class="info_content">' +
//       '<h3>Palace of Westminster</h3>' +
//       '<p>The Palace of Westminster is the meeting place of the House of Commons and the House of Lords, the two houses of the Parliament of the United Kingdom. Commonly known as the Houses of Parliament after its tenants.</p>' +
//       '</div>']
//   ];
//
//   // Display multiple markers on a map
//   var infoWindow = new google.maps.InfoWindow(), marker, i;
//
//   // Loop through our array of markers & place each one on the map
//   for( i = 0; i < markers.length; i++ ) {
//       var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
//       bounds.extend(position);
//       marker = new google.maps.Marker({
//           position: position,
//           map: map,
//           title: markers[i][0]
//       });
//
//       // Allow each marker to have an info window
//       google.maps.event.addListener(marker, 'click', (function(marker, i) {
//           return function() {
//               infoWindow.setContent(infoWindowContent[i][0]);
//               infoWindow.open(map, marker);
//           }
//       })(marker, i));
//
//       // Automatically center the map fitting all markers on the screen
//       map.fitBounds(bounds);
//   }
//
//   // Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
//   var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function(event) {
//       this.setZoom(3);
//       google.maps.event.removeListener(boundsListener);
//   });
//
// }
